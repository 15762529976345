import { array, boolean, number, object, string } from 'zod';

export interface Record {
  id: string;
  env_id: string;

  consent_url: string;
  cancel_url: string;
  block_url: string;
  is_add_friend: boolean;
  is_status: boolean;
  note: string;

  created_at: string;
  updated_at: string;
}

export interface Records extends Array<Record> {}

export interface Pagination {}

export interface ListSettingResponse {
  page: number;
  records: Records;
  total: number;
}

export const ListSettingSchema = object({
  records: array(
    object({
      id: string(),
      env_id: string(),
      consent_url: string(),
      cancel_url: string(),
      block_url: string(),
      is_add_friend: boolean(),
      is_status: boolean(),
      note: string(),
      created_at: string(),
      updated_at: string(),
    }),
  ),
  page: number(),
  total: number(),
});
