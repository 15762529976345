import { Box, Text } from '@chakra-ui/react';

import { ContentBox } from '@/pages/common';

type Props = {
  isSearch?: boolean;
};

export const BlankData = (props: Props) => {
  const { isSearch } = props;

  return (
    <ContentBox px="16px" py="16px">
      <Box py="8px" textAlign="center">
        <Text fontSize="lg" fontWeight="bold" marginBottom="8px">
          対象がありません。
        </Text>
        <Text fontSize="md">
          {isSearch
            ? '再度キーワードを入力して検索してください。'
            : '右上の[作成する]ボタンからLOOPASS LINE ログイン設定を作成してください。'}
        </Text>
      </Box>
    </ContentBox>
  );
};
