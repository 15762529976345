'use client';

import { WebAuth } from 'auth0-js';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { MAX_LIST_RECORD_PER_PAGE } from '@/constants';
import useGetRequest from '@/hooks/useGetRequest';
import { EnvironmentIdState } from '@/recoil';
import { ListSettingSchema } from '@/schemas/ListSetting.schema';

import {
  ApiErrorComponent,
  Content,
  ContentBox,
  Main,
  useAlert,
} from '@/pages/common';

import { logout } from '../common/services/auth';
import { TABLE_PAGINATION_HEIGHT } from '../common/table';
import { BlankData } from './setting/list/components/BlankData';
import { TableContents } from './setting/list/components/TableContents';

import {
  SEARCH_FIELD_HEIGHT,
  SearchField,
} from './setting/list/components/SearchField';

interface PageProps {
  authSession: string;
  authService?: WebAuth;
}

const endpoint = '/setting';

export default function App({ authSession, authService }: PageProps) {
  const router = useRouter();

  const environmentId = useRecoilValue(EnvironmentIdState);

  const [isInitLoading, setInitLoading] = useState(true);
  const [isApiError, setIsApiError] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isCreateLoading, setCreateLoading] = useState(false);
  const [hasSearchField, setHasSearchField] = useState(true);

  const { addToast } = useAlert();

  const {
    data,
    mutate,
    isLoading: isDataLoading,
    isError,
  } = useGetRequest(
    'lpslogin',
    environmentId
      ? `${endpoint}/${environmentId}?keyword=${encodeURIComponent(keyword)}&page=${page}&limit=${MAX_LIST_RECORD_PER_PAGE}`
      : null,
    ListSettingSchema,
    authSession,
  );

  const onCreateClick = () => {
    setCreateLoading(true);
    router.push(`${environmentId}/setting/create`);
  };

  useEffect(() => {
    if (sessionStorage.getItem('from_P12')) {
      addToast({
        title: 'LOOPASS LINE ログイン設定削除完了',
        message: 'LOOPASS LINE ログイン設定の削除が完了しました。',
      });
      sessionStorage.removeItem('from_P12');
    }
  }, []);

  useEffect(() => {
    if (!isDataLoading) {
      setInitLoading(false);
    }
  }, [isDataLoading]);

  useEffect(() => {
    if (data?.total) {
      const getTotalPages = Math.ceil(data.total / MAX_LIST_RECORD_PER_PAGE);

      setTotalPages(getTotalPages);
    }

    if (data?.total === 0 && !keyword) {
      setHasSearchField(false);
    } else {
      setHasSearchField(true);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (isError instanceof AxiosError || (isError as any).response) {
        const { status } = (isError as any).response;

        if (status === 401) {
          if (authService) logout(authService!);
        } else if (status === 403 || status === 404) {
          window.location.href = '/404';
        } else {
          // Handle error in inner component
          setIsApiError(true);
        }
      }
    } else {
      setIsApiError(false);
    }
  }, [isError]);

  return (
    <Main
      isContentLoading={environmentId === null && isInitLoading}
      authSession={authSession}
      authService={authService}
    >
      {isApiError ? (
        <ApiErrorComponent reloadFn={mutate} />
      ) : (
        <Content
          isRestrictedHeight
          heading="LOOPASS LINE ログイン設定一覧"
          hasTopRightButton
          topRightButtonLabel="作成する"
          topRightButtonAction={onCreateClick}
          topRightButtonLoading={isCreateLoading}
        >
          <ContentBox
            flexGrow={1}
            px="16px"
            py="16px"
            display={data && data.total ? undefined : 'flex'}
            flexDirection={data && data.total ? undefined : 'column'}
          >
            {hasSearchField && (
              <SearchField
                setKeyword={setKeyword}
                resetPage={() => setPage(1)}
              />
            )}
            {data && !data.total ? (
              <BlankData isSearch={hasSearchField} />
            ) : (
              <TableContents
                data={data}
                isTableLoading={isDataLoading}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                maxHeight={`calc(100% - (${SEARCH_FIELD_HEIGHT}px + ${TABLE_PAGINATION_HEIGHT}px + 24px))`}
              />
            )}
          </ContentBox>
        </Content>
      )}
    </Main>
  );
}
