import { Link, TableRowProps, Text } from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { MouseEvent } from 'react';
import { useRecoilValue } from 'recoil';

import Table from '@/pages/common/table';
import { EnvironmentIdState } from '@/recoil';
import { Record } from '@/schemas/ListSetting.schema';

import { LINE_LOGIN_URL } from '@/constants';
import styles from './TableData.module.css';

interface Props extends TableRowProps {
  record: Record;
}

export function TableData(props: Props) {
  const { record, ...otherProps } = props;

  const router = useRouter();
  const environmentId = useRecoilValue(EnvironmentIdState);

  const handleDetailClick = (id: string) => {
    router.push(`${environmentId}/setting/detail/${id}`);
  };

  const handleEditClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    router.push(`${environmentId}/setting/edit/${record.id}`);
  };

  return (
    <Table.Row
      cursor="pointer"
      className={styles.customTableStyle}
      onClick={() => handleDetailClick(record.id)}
      {...otherProps}
    >
      <Table.Column
        wordBreak="break-word"
        whiteSpace="pre-wrap"
        className="stickyColumnLeft"
      >
        <Link
          variant="text"
          color="brand.500"
          href={`${LINE_LOGIN_URL}/login?id=${record.id}`}
          target="_blank"
          onClick={(evt) => evt.stopPropagation()}
        >
          {`${LINE_LOGIN_URL}/login?id=${record.id}`}
        </Link>
      </Table.Column>
      <Table.Column wordBreak="break-all" whiteSpace="pre-wrap">
        {record.consent_url}
      </Table.Column>
      <Table.Column wordBreak="break-all" whiteSpace="pre-wrap">
        {record.cancel_url}
      </Table.Column>
      <Table.Column wordBreak="break-all" whiteSpace="pre-wrap">
        {record.block_url || '-'}
      </Table.Column>
      <Table.Column>
        <Text
          color="theme.text.secondary"
          bg={record.is_add_friend ? '#ddf2f4' : '#eceded'}
          px={4}
          py={1}
          borderRadius="30px"
          display="inline-block"
          fontWeight="medium"
          fontSize="sm"
        >
          {record.is_add_friend ? '有効' : '無効'}
        </Text>
      </Table.Column>
      <Table.Column>
        <Text
          color="theme.text.secondary"
          bg={record.is_status ? '#ddf2f4' : '#eceded'}
          px={4}
          py={1}
          borderRadius="30px"
          display="inline-block"
          fontWeight="medium"
          fontSize="sm"
        >
          {record.is_status ? '有効' : '無効'}
        </Text>
      </Table.Column>
      <Table.Column wordBreak="break-word" whiteSpace="pre-wrap">
        {record.note || '-'}
      </Table.Column>
      <Table.Column>
        {formatInTimeZone(
          new Date(record.created_at),
          'Japan',
          'yyyy/MM/dd HH:mm',
        )}
      </Table.Column>
      <Table.Column>
        {formatInTimeZone(
          new Date(record.updated_at),
          'Japan',
          'yyyy/MM/dd HH:mm',
        )}
      </Table.Column>
      <Table.Column
        minWidth="60px"
        width="60px"
        p={0}
        data-testid="table-row-edit"
        onClick={handleEditClick}
        className="stickyColumnRight"
      >
        <Image
          src="/icons/pen.svg"
          alt="edit"
          width={20}
          height={20}
          style={{ margin: '0 auto' }}
        />
      </Table.Column>
    </Table.Row>
  );
}
