'use client';

import { Box, Input, Text } from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Button } from '@/pages/common';
import { EnvironmentIdState } from '@/recoil';

export const SEARCH_FIELD_HEIGHT = 89;

type Props = {
  setKeyword: (value: string) => void;
  resetPage: () => void;
};

export const SearchField = (props: Props) => {
  const { setKeyword, resetPage } = props;

  const environmentId = useRecoilValue(EnvironmentIdState);

  const [search, setSearch] = useState<string>('');

  const handleSearch = (evt: FormEvent) => {
    evt.preventDefault();

    // Setting this calls the search api
    setKeyword(search || '');
    resetPage();
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setSearch('');
    setKeyword('');
    resetPage();
  };

  useEffect(() => {
    handleClearSearch();
  }, [environmentId]);

  return (
    <Box
      width="100%"
      py={2}
      px={3}
      mb={2}
      bg="#f9f9f9"
      borderRadius="8px"
      height={`${SEARCH_FIELD_HEIGHT}px`}
    >
      <Box>
        <Text fontSize="sm" lineHeight="short" mb={2}>
          ログイン/同意時URL・備考キーワード
        </Text>
      </Box>
      <Box as="form" display="flex" onSubmit={handleSearch}>
        <Box flexGrow={1} mr={4}>
          <Input
            value={search}
            onChange={handleChangeSearch}
            placeholder="ログイン/同意時URL、または備考キーワードを入力"
            bg="#fff"
            focusBorderColor="#b5e3eb"
            _placeholder={{ color: '#bebfbf' }}
            fontSize="md"
            minHeight="43px"
            px="12px"
            borderRadius="4px"
            borderWidth="1px"
            borderColor="#eceded"
          />
        </Box>
        <Button
          type="submit"
          variant="link"
          fontWeight="bold"
          fontSize="sm"
          color="#469db1"
          minWidth="unset"
          px={2}
          py={3}
          mr={1}
        >
          絞り込み
        </Button>
        <Button
          type="button"
          variant="link"
          fontWeight="bold"
          fontSize="sm"
          minWidth="unset"
          px={2}
          py={3}
          onClick={handleClearSearch}
          isDisabled={!search}
        >
          クリア
        </Button>
      </Box>
    </Box>
  );
};
